// This file contains the logic for the EsriMap class and is designed to be used with stimulus.js.

// Example usage:
// import EsriMap from './esri_map';
//
// connect () {
//   const mapIdentifier = 'formMap';
//   const mapInstance = new EsriMap(mapIdentifier);
//   const endpoint = $(`#${mapIdentifier}`).data('url');
//   mapInstance.getPlacesAndRender(endpoint);
// }

const esriAPIKey = 'AAPK2720562ea0aa448d9ffd4e56e720ce8ahvYHI83h5qAKHSAmM002AmLLd1zn_dMLh1yJNI8tE-TXpiuacoFZ7NlNo6p6TJQO';


export default class EsriMap {
  constructor(mapIdentifier) {
    const map = L.map(mapIdentifier, { zoomSnap: 0.5, maxZoom: 20 });
    const basemapLayers = { Streets: L.esri.Vector.vectorBasemapLayer('ArcGIS:Streets', { apiKey: esriAPIKey, }).addTo(map) };

    // Add the basemap layers to the map
    // L.control.layers(this.basemapLayers, null, { collapsed: false }).addTo(this.map);
    map.zoomControl.setPosition('bottomright');

    this.apiKey = esriAPIKey;
    this.map = map;
  }

  addPinToMap(pin, link, id) {
    let lat = Math.round(pin.latitude * 1000000) / 1000000;
    let lon = Math.round(pin.longitude * 1000000) / 1000000;
    let markers = L.featureGroup();
    let marker = L.marker([lat, lon]);
    const classInstance = this;

    markers.addLayer(marker);
    classInstance.map.addLayer(markers);

    if (marker._icon){
      marker._icon.classList.add(id);
    }
    marker.on('click', function (e) {
      classInstance.loadPlaceDetail(link);
      classInstance.applySelectedMarkerLogic(e.target);
    });
  }

  loadPlaceDetail(link) {
    $('#modal-window').find('.modal-content').load(link);
    $('#modal-window').modal();
  }

  applySelectedMarkerLogic(marker) {
    $('.leaflet-marker-icon').map(function () {
      $(this).removeClass('selected-marker');
    });
    if (!L.DomUtil.hasClass(marker._icon, 'selected-marker')) {
      L.DomUtil.addClass(marker._icon, 'selected-marker');
    }
  }

  getPlacesAndRender(endpoint, delay=0) {
    const baseUrl = `${window.location.protocol}//${window.location.host}${endpoint}`;
    // Set the scope to the class instance so that
    // we can access the class instance methods in
    // the ajax done function.
    const classInstance = this;

    // Delay the request to the server to allow the map to load
    setTimeout(function () {
      // Make the request to the server
      $.ajax({
        url: baseUrl,
        method: 'GET',
        contentType: 'application/json',
        dataType: 'json',
      }).done(function (data) {
        if (data['places']) {
          let places = data['places'];
          if (places.length > 0) {
            classInstance.map.fitBounds(
              places.map((place) => {
                return [place['latitude'], place['longitude']];
              }),
              { padding: [10, 10] }
            );
            places.forEach((place) => {
              let pin = { latitude: place['latitude'], longitude: place['longitude'] };
              let link = place['link'];
              let id = place['id'];
              classInstance.addPinToMap(pin, link, id)
            });
          }
        } else if (data['error']) {
          window.location = '/';
        }
      });
    }, delay);
  }
}
