// This file should handle any JS listeners for the /my_stories/* pages that
// are not covered by the other stimulus controller actions.
import { desktopBreakpoint } from '../config';

document.addEventListener('turbo:load', function () {
  // Listen to any link click outside of the .new_my_story form and
  // stop the event from propagating and instead open the modal to confirm save.
  $(document).on('click', 'a', function(event) {
    const selectedLinkHref = $(this).attr('href');
    // Only enable this functionality on the my_story edit and new pages
    if (window.location.pathname.includes('/my_stories/edit') || window.location.pathname.includes('/my_stories/new')) {
      // If the any of the parents DONT' include .new_my_story then show the modal
      // Essentially any link clicked outside of this form will trigger the modal.
      if ($(this).parents('.my-stories-form').length === 0) {
        event.preventDefault();
        // Load the the correct modal based on the screen size.
        let modalId;
        if ($(window).width() < desktopBreakpoint) {
          modalId = '#exitModalMobile';
        } else {
          modalId = '#exitModalDesktop';
        }
        $(modalId).modal('show');
        $(modalId).on('click', '#exitModalSave', function() {
          // NOTE: Doing $('.new_my_story').trigger('submit') will not work
          // as it submits the form twice. Instead, we need to update the hidden field
          // and allow the form to submit "naturally" with `return true`. Then we can
          // know the desired action in the controller from the hidden field.
          $(modalId).find('#selected_href').val(selectedLinkHref);
          return true;
        });
        $(modalId).on('click', '#exitModalDiscard', function() {
          window.location.href = selectedLinkHref;
        });
      }
    }
  });
});
