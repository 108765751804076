import { Controller } from "@hotwired/stimulus"
import { desktopBreakpoint } from '../../config';
import { instantiateMapAndRender, mapHasNotBeenInstantiated } from '../my_stories/index';

const mapIdentifier = 'recommendedPlacesMapDesktop';

export default class extends Controller {
  connect() {
    if ($(window).width() >= desktopBreakpoint) {
      if (mapHasNotBeenInstantiated(mapIdentifier)) {
        instantiateMapAndRender(mapIdentifier);
      }
    }
  }
}
