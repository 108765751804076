import { Controller } from "@hotwired/stimulus"
import { desktopBreakpoint } from '../../config';
import { instantiateMapAndRender, mapHasNotBeenInstantiated } from '../my_stories/index';

const mapIdentifier = 'recommendedPlacesMapMobile';

export default class extends Controller {
  connect() {
    if ($(window).width() < desktopBreakpoint) {
      if (mapHasNotBeenInstantiated(mapIdentifier)) {
        // Delay the request so that the collapse has time to show before rendering the map
        // Otherwise, the map will be cutoff at the bottom of the collapse.
        const delay = 0;
        instantiateMapAndRender(mapIdentifier, delay);
      }
    }
  }
}
