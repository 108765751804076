import { Controller } from "@hotwired/stimulus"
import EsriMap from '../../esri_map';

export default class extends Controller {
  connect() {
    const mapInstance = new EsriMap('places-map');
    let marker = {};
    const map = mapInstance.map

    let markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
    });

    const options = {
      timeout: 10000,
    };

    function success(pos) {
      const crd = pos.coords;
      map.setView([crd.latitude, crd.longitude], 10);
      map.getCenter();
      // Initial Render
      searchWithParams();
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      // TODO: This should default to the user zipcode.
      let lat = 32.7157;
      let long = -117.161087;
      map.setView([lat, long], 10);
      // Initial Render
      searchWithParams();
    }

    // If cookies for lng and lng are not found try to get the users location.
    if (getCookie('lat') == '' || getCookie('lng') == '') {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      let zoomLevel = getCookie('zoom');
      zoomLevel = zoomLevel == '' ? 10 : zoomLevel;
      map.setView([getCookie('lat'), getCookie('lng')], zoomLevel);
      map.getCenter();
      // Initial Render
      searchWithParams();
    }

    map.on('moveend', function () {
      markers.clearLayers();
      searchWithParams();
      setCookie('zoom', map.getZoom());
    });

    $('body').on('change', '.categories', function (e) {
      markers.clearLayers();
      searchWithParams();
    });

    $('body').on('change', '.my-places', function (e) {
      markers.clearLayers();
      searchWithParams();
    });

    $('body').on('change', '.user-saved-places', function (e) {
      markers.clearLayers();
      searchWithParams();
    });

    function searchWithParams() {
      let options = {
        categoryId: $('.categories').find(':selected').val(),
        interest: $('.my-places').find(':selected').val(),
        placesType: $('.user-saved-places').find(':selected').val(),
      };
      getPlacesAndRender(options);
    }

    function searchParams(options = {}) {
      let params = [];
      if (options['categoryId']) {
        params.push(`category_id=${options['categoryId']}`);
      }
      if (options['interest']) {
        params.push(`interest=${options['interest']}`);
      }
      if (options['placesType']) {
        params.push(`places_type=${options['placesType']}`);
      }

      params.push(`lat=${map.getCenter().lat}`);
      params.push(`lng=${map.getCenter().lng}`);

      setCookie('lat', map.getCenter().lat, 1);
      setCookie('lng', map.getCenter().lng, 1);

      return params.join('&');
    }

    function getPlacesAndRender(options = {}) {
      let getUrl = window.location;
      let isAdminMap = getUrl.href.includes('admin');
      let params = searchParams(options);
      let params_with_admin_flag = [`admin=${isAdminMap}`, params].join('&');
      let endpoint = '/places/search_map';
      let baseUrl =
        getUrl.protocol +
        '//' +
        getUrl.host +
        endpoint +
        '?' +
        `${params_with_admin_flag}`;

      // Loading spinner
      $('.places-count').html(
        "<div class='spinner-border spinner-grow-sm text-primary' role='status'><span class='sr-only'>Loading...</span></div>"
      );

      $.ajax({
        url: baseUrl,
        method: 'GET',
        contentType: 'application/json',
        dataType: 'json',
      }).done(function (data) {
        if (data['places']) {
          let places = data['places'];
          $('.places-count').text(places.length);
          if (places.length > 0) {
            // map.fitBounds(
            //   places.map((place) => {
            //     return [place['latitude'], place['longitude']];
            //   }),
            //   { padding: [10, 10] }
            // );
            places.forEach((place) => {
              addPinToMap(
                { latitude: place['latitude'], longitude: place['longitude'] },
                place['link']
              );
            });
          }
        } else if (data['error']) {
          window.location = '/';
        }
      });
    }

    function addPinToMap(pin, link) {
      let lat = Math.round(pin.latitude * 1000000) / 1000000;
      let lon = Math.round(pin.longitude * 1000000) / 1000000;

      marker = L.marker([lat, lon]);
      markers.addLayer(marker);
      map.addLayer(markers);
      marker.on('click', function (e) {
        loadPlaceDetail(link);
        applySelectedMarkerLogic(e.target);
      });
    }

    function loadPlaceDetail(link) {
      $('#modal-window').find('.modal-content').load(link);
      $('#modal-window').modal();
    }

    function applySelectedMarkerLogic(marker) {
      $('.leaflet-marker-icon').map(function () {
        $(this).removeClass('selected-marker');
      });
      if (!L.DomUtil.hasClass(marker._icon, 'selected-marker')) {
        L.DomUtil.addClass(marker._icon, 'selected-marker');
      }
    }

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }

    // Highlight Admin Buttons
    let addclass = 'bg-gray';
    let $cols = $('body').on('click', '.admin-buttons', function (e) {
      $cols.removeClass(addclass);
      $(this).addClass(addclass);
    });
  }
}
